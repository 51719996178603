@import '~@arcault-ui/shared-components/dist/styles.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dashboard {
  flex: 1;
  width: 1300px;
  overflow-y: scroll
}

.card-no-shadow {
  box-shadow: none;
}

.neu-card {
  border-radius: 8px;
  background: white;
  box-shadow:  7px 7px 14px #bebebe, -7px -7px 14px #ffffff
}

.neu-card-low {
  border-radius: 8px;
  background: white;
  box-shadow:  5px 5px 10px #bebebe, -5px -5px 10px #ffffff
}

.neu-card-min {
  border-radius: 8px;
  background: white;
  box-shadow:  3px 3px 6px #bebebe, -3px -3px 6px #ffffff
}

.min-card {
  padding: 0;
  gap: 8;
}

.dial-num {
  gap: 4px;
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* 
* typograph
*
*/

h4 {
  font-size: 20px;
  line-height: 28px;
}

caption {
    font-size: var(--font-size-caption);
    font-weight: var(--font-weight-caption);
    line-height: var(--line-height-caption);
}

.header {
    background-color: white;
    box-shadow: var(--elevation-1);
    width: 100%;
    height: var(--spacing-14);
    padding: var(--spacing-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
   
}

.header .profile-icon .arcault-avatar {
  height: var(--spacing-10);
  width: var(--spacing-10);
}

.header .chakra-avatar__initials {
  font-size: var(--spacing-4);
  color: white;
}


.dot {
  position: relative;
}

.dot::after {
  content: '';
  position: absolute;
  top: -5px;
  right: -5px;
  width: 10px;
  height: 10px;
  background-color: var(--color-accent);
  border-radius: 50%;
}
 
.profile-icon {
  background-color: white;
}

.call-button {
    position: absolute;
    bottom: 18px;
    right: 18px;
    background-color: var(--color-primary);
    color: white;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
}

.header .profile-icon, .header .profile-icon:hover {
  background-color: white;
}

@media (max-width: 600px) {
  .header {
    height: var(--spacing-12);
    padding: var(--spacing-2);
  }
  .header .hamburger-menu {
    display: flex;
  }
  
  .header .arcault-logo, .header .arcault-logo img {
    height: 32px;
  }
  
  .navigation.desktop-nav {
    display: none;
  }
  
  .header .profile-icon {
    background-color: white;
  }
  
  .header .profile-icon .chakra-avatar {
    height: 32px;
    width: 32px;
  }
}


.background-layer {
  /*background-image: url('./background.svg'); */
  /* Set the background image */
  background: url('big_background.png');

  /* Make the image cover the full screen and fix its position */
  /*background-attachment: fixed;
  background-position: -250px -100px;
  background-size: 600px 800px;
  background-repeat: no-repeat;'*/
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  opacity: 0.8;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: -1;

}

.main-content { 
  padding: var(--spacing-8);
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--spacing-14)); /* Adjust height to be full height after the header */
}

.main-stack {
  gap: 0px;
}

.navigation {
  background-color: #fff;
  box-shadow: var(--elevation-right-1);
  left: 0;
  padding: var(--spacing-2);
  position: fixed;
  top: var(--spacing-12);
  width: 291px;
  z-index: 1;
}

.navigation .icon {
    padding-right: var(--spacing-2);
}

.navigation .nav-item {
    border-radius: var(--spacing-2);
    height: var(--spacing-14);
    display: flex;
    align-items: center;
    padding: var(--spacing-4);
    margin-bottom: var(--spacing-2);
    font-size: var(--font-size-subheading);
    line-height: var(--line-height-subheading);
    font-weight: var(--font-weight-subheading);
}

a.nav-item {
  text-decoration: none;
  color: var(--color-primary);
}

.navigation .nav-item-inner {
  align-items: center;
}



/* Tablet Size: Adjust for medium screens */
@media (max-width: 1024px) {
  .navigation {
      width: 98px;
  }
  
  .navigation .icon {
    padding-right: 0;
    padding-bottom: var(--spacing-1);
  }
  
  .navigation .nav-item {
      padding: var(--spacing-2);
      font-size: var(--font-size-caption);
      line-height: 14px;
      height: 72px;
      flex-direction: column;
      text-align: center;
  }
  
  .navigation .nav-item-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
  }
}

@media (max-width: 600px) {
  .navigation {
    width: 192px;
    position: fixed;
    background-color: white;
    z-index: 1;
    box-shadow: var(--elevation-right-1);
    top: var(--spacing-12);
    left: 0px;
  }
  
  .navigation .icon {
    padding-right: var(--spacing-1);
    padding-bottom: 0;
    font-size: var(--font-size-caption);
  }
  
  .navigation .nav-item {
    border-radius: var(--spacing-2);
    height: var(--spacing-8);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    text-align: left;
  }
  
  .navigation .nav-item-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
  }
}

.navigation .active, .navigation .active:hover {
   background-color: var(--color-primary);
}

.navigation .active div, .navigation .active:hover div {
   color: var(--color-secondary);
}

.navigation a:hover {
   background-color: var(--color-neutral-light);
   box-shadow: var(--elevation-1);
}

.navigation a:hover div {
    color: var(--color-accent);
}

.navigation a:active {
   background-color:var(--color-neutral-30);
   box-shadow: var(--elevation-0);
}

/* to move */

.arcault-info-assets-table {
  width: 100%;
}

.leads-dashboard {
  flex: 1;
  display: flex;
  /* justify-content: flex-start;*/ 
}

.leads-dashboard .card-body {
  display: flex;
  flex-direction: column;
}

.full-height-card {
  height: 100%
}

.leads-dashboard .h2 {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-top: 12px;
}

.leads-dashboard-container {
  /* gap: 24px;*/
  flex: 1;
}

.leads-dashboard-container .proposal-half:first-child {
  border-right: 1px solid var(--color-neutral-10);
  padding-right: 24px;
}

.lead-meta-data {
  display: flex;
  flex-wrap: wrap;
}

.lead-meta-data .arcault-h-stack {
  flex: 0 1 50%;
}

.lead-meta-data .arcault-h-stack .text {
  flex: 2;
}

.lead-meta-data .arcault-h-stack .text:first-child {
  text-align: right;
  flex: 3;
}



.leads-individual-header {
  align-items: center;
}



.leads-dashboard > .card-header {
  display: none;
}

.flex-zero {
  flex: 0;
}

.proposal-half {
  flex: 1;
}

.customer-info {
  width: 300px;
  border-right: 1px solid var(--color-neutral-10);
  padding-right: 24px;
}

.customer-info-item {
  display: flex;
  justify-content: middle;
  padding-bottom: var(--spacing-2);
}

.customer-info-item .icon {
  padding-right: var(--spacing-2);
}

.customer-vault {
  flex: 1;
}

.your-proposal {
  width: 800px;
  margin: 0 auto;
  text-align: left;
  border: 1px solid var(--color-neutral-10);
  padding: 48px;
  border-radius: 0px;
}

.your-proposal .proposal-textarea {
  flex: 1 1 200px;
  display: flex;

}

.your-proposal .arcault-input-group {
  display: flex;
  flex: 1;
}

.your-proposal .proposal-textarea .form-input {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.your-proposal > .form-input:last-child {
  flex: 0;
}

.your-proposal textarea {
  flex: 1;
}



.table-row-rfp, .table-row-customer {
  height: 36px; 
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.table-row-rfp .table-column-summary {
  padding-left: 12px;
}

.table-row-rfp:hover, .table-row-customer:hover {
  box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.3);
  border-bottom: none !important;
  background-color: var(--color-neutral-5);
}

