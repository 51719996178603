.tab-list {
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
}

.tab-item {
  padding: 8px 16px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.tab-item.active {
  border-bottom: 2px solid var(--color-primary-light);
  font-weight: bold;
}

.tab-panel {
  padding: 16px;
}