.header {
    width: 100%;
    height: var(--spacing-14);
    padding: var(--spacing-2);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header .arcault-logo {
    height: 32px;
  }

  .header .arcault-logo img {
    height: 24px;
    padding-right: var(--spacing-1);
  }
  
.header .arcault-logo {
    font-size: 32px;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: var(--color-primary);
    
}