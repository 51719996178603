@import '../../App.css';

.profile-card {
    margin-top: var(--spacing-8);
    flex: 0 0 600px;
}

.profile-card .arcault-avatar {
    height: 200px;
    width: 200px;
}

.profile-container {
    padding: 16px
}