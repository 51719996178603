.lead-card {
    flex: 1 1 500px;
}

.table-header {
    flex: 0 0 36px;
}

.view-switcher {
    margin-top: 14px;
    height: 48px;
}

.table-controls { 
    flex: 0;
}

.lead-card-title {
    height: 64px;
    display: flex;
    align-items: center;
}

.stat-stack {
    padding: var(--spacing-4) var(--spacing-8);
    flex: 0 0 100px;
}

.stat-stack .arcault-stack:first-child {
    flex: 2;
}

.stat-card.card {
    border: 1px solid var(--color-neutral-10);
    padding: var(--spacing-4);
}

/*.stat-card.card .h1 {
    color: var(--color-navy-40);
}*/

.stat-card .stat-value {
    padding-top: var(--spacing-3);
    align-items: center;
}

.stat-card .stat-percent {
    position: absolute;
    right: 20px;
}

.stat-card .card-body {
    padding: 0;
}

.stat-card .card-header, .stat-card .card-footer {
    display: none
}

.vertical-divider {
    border-left: 1px solid var(--color-neutral-20);
    height: 100%;
}

.arcault-stack.arcault-stack-low-gap {
    gap: 4px
}

.rfp-summary {
    width: 900px;
    margin: 0 auto;
}

.your-proposal .arcault-avatar {
    height: var(--spacing-12);
    width: var(--spacing-12);
}

.proposal-header {
    padding-bottom: var(--spacing-20);
}

.proposal-header-left {
    align-items: flex-start;
}

.leads-dashboard .proposal-header-left .text {
    padding-top: 0px;
}

.leads-dashboard .proposal-header-right {
    gap: 0px;
    align-items: flex-end;
}